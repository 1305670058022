import React from "react"
import { OverwritedLink } from "../thanks/index.style"
import { LargeTitle, Paragraph } from "./index.style"

const LeasingGalery = () => {
  return (
    <div>
      <LargeTitle>Leasing Gallery</LargeTitle>
      <br />
      <Paragraph>
        Address
        <br />
        <OverwritedLink
          href="https://goo.gl/maps/EcSDf3ayiGvpHLuE9"
          target={"_blank"}
          rel="noreferrer"
        >
          243 W 28th St, New York, NY 10001
        </OverwritedLink>
        <br />
        Hours
        <br />
        Monday-Sunday: 9am-6pm
        <br />
        <br />
        Phone
        <br />
        <OverwritedLink href="tel:2125517829">212 551 RUBY</OverwritedLink>
        <br />
        Email
        <br />
        <OverwritedLink
          href="mailto:leasing@rubychelsea.com"
          target={"_blank"}
          rel="noreferrer"
        >
          leasing@rubychelsea.com
        </OverwritedLink>
      </Paragraph>
    </div>
  )
}

export default LeasingGalery
